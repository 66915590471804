import { effect, Inject, Injectable, PLATFORM_ID, signal } from '@angular/core';
import { getWindow } from 'ssr-window';

const getItem = (key: string): string | null => {
  const storedStatus = localStorage.getItem(key);
  if (storedStatus) {
    let parsedStatus:
      | {
          value: string;
          expireAt: number;
        }
      | undefined;
    try {
      parsedStatus = JSON.parse(storedStatus);
    } catch (error) {
      /* empty */
    }
    if (parsedStatus && parsedStatus.expireAt > Date.now()) {
      return parsedStatus.value;
    }
  }
  return storedStatus;
};

const setItem = (key: string, value: string, expiry: number): void => {
  localStorage.setItem(
    key,
    JSON.stringify({
      value,
      expireAt: Date.now() + expiry,
    })
  );
};

declare global {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const SitemuleAIClient: any;
}
const win = getWindow();
const expiry = 1 * 24 * 60 * 60 * 1000; // 1 day

interface AA {
  onReady: (cal: () => void) => void;
  onMaximize: (cal: () => void) => void;
  onMinimize: (cal: () => void) => void;
  onClose: (cal: () => void) => void;
  onNewConversation: (cal: () => void) => void;
  onMessageLinkClick: (cal: (data: { link: string; label: string }) => void) => void;
  onSuggestions: (onSuggestions: (suggestions: string[]) => void) => void;
  minimize: () => Promise<void>;
  maximize: () => Promise<void>;
  focusInput: () => Promise<void>;
  addMessage: (message: string) => Promise<void>;
  askChatGPT: (prompt: string) => Promise<string>;
  addSuggestedMessages: (messages: string[]) => Promise<void>;
  startNewConversation: () => Promise<void>;
  destroy: () => Promise<void>;
  getMessagesCount: () => Promise<number>;
}

@Injectable({
  providedIn: 'root',
})
export class SitemuleAIService {
  private injectingScript = false;
  private injectingIframe = false;
  public assistantStatus = signal<'hidden' | 'icon' | 'expanded-small' | 'expanded-large'>('hidden');
  public suggestions = signal<string[]>([]);
  private readonly script = (() => {
    const script = win.document.createElement('script');
    script.defer = true;
    script.src = `https://test-assistant.sitemule.com/client.js?bust=${Date.now()}`;
    return script;
  })();

  private ai?: AA;

  private firstEffectProcessed = false;
  private eff = effect(() => {
    const assistantStatus = this.assistantStatus();
    if (!this.firstEffectProcessed) {
      this.firstEffectProcessed = true;
      return;
    }
    setItem('sitemule-ai-assistant-status', assistantStatus, expiry);
  });

  constructor(@Inject(PLATFORM_ID) private platformId: string) {}

  public get injecting() {
    return this.injectingScript || this.injectingIframe;
  }

  private injectScript(): Promise<void> {
    return new Promise(res => {
      // If SitemuleAIClient exists, it means script is already injected
      if (typeof SitemuleAIClient !== 'undefined') {
        res();
        return;
      }
      if (this.injectingScript) {
        throw `Double injection`;
      }
      this.injectingScript = true;
      this.script.onload = () => {
        this.injectingScript = false;
        res();
      };
      win.document.head.append(this.script);
    });
  }

  private injectIframe(el: HTMLElement): Promise<void> {
    return new Promise(res => {
      if (this.injectingIframe) {
        throw `Double injection`;
      }
      if (this.ai) {
        res();
        return;
      }
      this.injectingIframe = true;

      const ai = new SitemuleAIClient({
        organization: 'eriksen',
        targetElement: el,
      });

      ai.onReady(() => {
        this.injectingIframe = false;
        this.ai = ai;
        res();
      });
    });
  }

  private addListeners() {
    this.onMaximize(() => {
      this.assistantStatus.set('expanded-large');
    });
    this.onMinimize(() => {
      this.assistantStatus.set('expanded-small');
    });
    this.onClose(() => {
      this.assistantStatus.set('icon');
    });
    this.onNewConversation(() => {
      // setTimout because it takes around 1 sec to render new thread in iframe
      setTimeout(() => {
        if (this.page?.type === 'product' || this.page?.type === 'catalog') {
          this.getQuestions().then(questions => {
            this.addSuggestedMessages(questions);
          });
        }
      }, 2000);
    });
    this.ai?.onSuggestions(suggestions => {
      this.suggestions.set(suggestions);
    });
  }

  public init(el: HTMLElement): Promise<void> {
    return this.injectScript()
      .then(() => {
        return this.injectIframe(el);
      })
      .then(() => {
        this.addListeners();
      })
      .then(() => {
        const initialState = (() => {
          const storedStatus = getItem('sitemule-ai-assistant-status');

          if (storedStatus === 'icon' || storedStatus === 'expanded-small' || storedStatus === 'expanded-large') {
            return storedStatus;
          }
          return 'icon';
        })();
        if (initialState === 'icon') {
          this.showIcon();
          return;
        }
        if (initialState === 'expanded-small') {
          this.expandSmall();
          return;
        }
        if (initialState === 'expanded-large') {
          this.expandLarge();
          return;
        }
      });
  }

  private _onReady(fn: () => void, counter = 1) {
    if (counter >= 500) {
      throw `Sitemule AI took too long time to be ready`;
    }
    if (this.ai && !this.injectingIframe) {
      fn();
    } else {
      win.setTimeout(() => {
        this._onReady(fn, counter + 1);
      }, 100);
    }
  }

  public onReady(): Promise<void> {
    return new Promise(res => {
      this._onReady(() => {
        res();
      });
    });
  }

  public isReady() {
    return !!this.ai;
  }

  public onMaximize(onMaximize: () => void) {
    return this.ai?.onMaximize(onMaximize);
  }

  public onMinimize(onMinimize: () => void) {
    return this.ai?.onMinimize(onMinimize);
  }

  public onClose(onClose: () => void) {
    return this.ai?.onClose(onClose);
  }

  public onNewConversation(onNewConversation: () => void) {
    return this.ai?.onNewConversation(onNewConversation);
  }

  public onMessageLinkClick(onMessageLinkClick: (data: { link: string; label: string }) => void) {
    return this.ai?.onMessageLinkClick(onMessageLinkClick);
  }

  public showChatView() {
    const assistantStatus = this.assistantStatus();
    if (assistantStatus === 'hidden' || assistantStatus === 'icon') {
      this.expandSmall();
    }
  }
  public hide(): Promise<void> {
    return new Promise(res => {
      this.assistantStatus.set('hidden');
      res();
    });
  }

  public showIcon(): Promise<void> {
    return new Promise(res => {
      this.assistantStatus.set('icon');
      res();
    });
  }

  public expandSmall(): Promise<void> {
    const doAutoFocus = this.assistantStatus() === 'icon';
    if (!this.ai) {
      throw `AI Not ready`;
    }
    return this.ai.minimize().then(() => {
      if (doAutoFocus) {
        this.focusInput();
      }
    });
  }

  public expandLarge(): Promise<void> {
    if (!this.ai) {
      throw `AI Not ready`;
    }
    return this.ai.maximize();
  }

  public focusInput(): Promise<void> {
    if (!this.ai) {
      throw `AI Not ready`;
    }
    return this.ai.focusInput();
  }

  public sendMessage(message: string): Promise<void> {
    if (!this.ai) {
      throw `AI Not ready`;
    }
    return this.ai.addMessage(message);
  }
  public addSuggestedMessages(suggestions: string[]): Promise<void> {
    if (!this.ai) {
      throw `AI Not ready`;
    }
    return this.ai.addSuggestedMessages(suggestions);
  }

  public getMessagesCount(): Promise<number> {
    if (!this.ai) {
      throw `AI Not ready`;
    }
    return this.ai.getMessagesCount();
  }

  public startNewConversation(): Promise<void> {
    if (!this.ai) {
      throw `AI Not ready`;
    }
    return this.ai.startNewConversation();
  }

  public askChatGPT(prompt: string): Promise<string> {
    if (!this.ai) {
      throw `AI Not ready`;
    }
    return this.ai.askChatGPT(prompt);
  }

  public getQuestions(): Promise<string[]> {
    return this.askChatGPT(
      `Analyser indholdet fra denne webside ${location.toString()}, og giv mig fire relevante spørgsmål baseret på brugerens behov og interesser i denne kategori. Jeg ønsker kun at se de spørgsmål, der genereres, og intet andet.`
    ).then(res => {
      return res
        .split('\n')
        .filter(i => {
          return i.match(/^\d/);
        })
        .map(i => i.replace(/^\d+\.?\s?/, ''));
    });
  }

  public destroy(): Promise<void> {
    if (!this.ai) {
      return Promise.resolve();
    }
    return this.ai.destroy().then(() => {
      this.ai = undefined;
      this.hide();
    });
  }

  private page?: {
    type: string;
  };
  public setPage(data?: { type: string }) {
    if (!this.injecting && !this.ai) {
      return;
    }
    this.page = data;
    this.onReady().then(() => {
      this.addSuggestedMessages([]);
      if (data?.type === 'product' || data?.type === 'catalog') {
        this.getMessagesCount().then(count => {
          if (!count) {
            this.getQuestions().then(questions => {
              this.addSuggestedMessages(questions);
            });
          }
        });
      }
    });
  }
}
